import {formatPhone} from 'goodeggs-formatters';

import {ISerializedUser} from 'domain/users';

// TODO: (@shermam) This might be a duplicate of
// https://github.com/goodeggs/garbanzo/blob/cfecaea840444d5aab0ba9d8ade529d0dfac9173/src/domain/users/client_types.ts
// consider resolving/deleting these types
export interface UIUser {
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string | null;
  newsletter?: boolean;
  createdAt?: Date;
  sms?: {
    requiresUserAction: boolean;
    subscribed: boolean;
  };
  promptEmails?: {
    subscribed: boolean;
    openBasketLastSentAt?: Date;
    postDeliveryLastSentAt?: Date;
  };
  substitutions?: boolean;
  membership?: {
    isMember: boolean;
    startDate: Date;
    endDate: Date | null;
  };
  survey: {
    hasAnswered: boolean;
  };
  id: string;
  orderCount: number;
  active_card: {
    paymentMethodId?: string | null;
    type?: string;
    last4?: string;
    exp_month?: string;
    exp_year?: string;
  };
  hasPassword?: boolean;
  masquerading?: boolean;
  availableBalance?: number | null;
  referralId?: string;
}

// TODO: (@shermam) Consider using TS function overloading
// to have a different return type depending on whether or not `minimal` is set to `true`
export function serializeUser(
  user?: (ISerializedUser & {passwordHash?: string}) | null,
  {
    userStoreCreditBalance,
    masquerading,
    minimal,
  }: {
    userStoreCreditBalance?: number | null;
    masquerading?: boolean | null;
    minimal?: boolean | null;
  } = {},
): UIUser | null {
  if (user == null) {
    return null;
  }

  const serializedUser: UIUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    referralId: user.referralIds.web,
    createdAt: user.createdAt,

    ...(minimal === true
      ? {}
      : {
          phone: user.phone,
          newsletter: user.newsletter,
          sms: user.sms,
          promptEmails: user.promptEmails,
          substitutions: user.substitutions,
          membership: user.membership,
        }),

    id: user._id.toString(),
    orderCount: user.statistics.ordersPlacedCount,
    active_card:
      user.stripe?.customer?.active_card?.paymentMethodId != null ||
      user.stripe?.customer?.active_card?.token != null
        ? {
            paymentMethodId: user.stripe.customer.active_card.paymentMethodId,
            type: user.stripe.customer.active_card.type,
            last4: user.stripe.customer.active_card.last4,
            exp_month: user.stripe.customer.active_card.exp_month,
            exp_year: user.stripe.customer.active_card.exp_year,
          }
        : {},
    survey: {
      hasAnswered: user.survey?.hasAnswered || false,
    },
  };

  if (serializedUser.phone != null) {
    serializedUser.phone = formatPhone(serializedUser.phone);
  }

  if (!minimal) {
    serializedUser.hasPassword = user.hasPassword || user.passwordHash != null; // passwordHash is deprecated, not returned from UsersDomain
  }

  if (masquerading != null) {
    serializedUser.masquerading = masquerading;
  }

  if (userStoreCreditBalance != null) {
    serializedUser.availableBalance = userStoreCreditBalance;
  }

  return serializedUser;
}
