import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import Footer from 'web/components/footer';
import Deck, {Card, actions as deckActions, reducer as deckReducer} from 'web/components/deck';
import {reducer as fulfillmentDaysReducer} from 'web/helpers/fulfillment_days_duck';
import {reducer as zipCodeReducer} from 'web/helpers/zip_code_duck';
import {actions as modalActions, reducer as modalReducer} from 'web/helpers/modal_duck';
import basketDuck from 'web/helpers/basket_duck';
import {reducer as favoritesDuckReducer} from 'web/helpers/favorites_duck';
import AuthFlowModal from 'web/components/auth_flow_modal';
import Modal from 'web/components/modal';
import {AppDispatch, PageType} from 'web/helpers/redux_client';
import SurveyPopIn from 'web/components/survey_popin';
import useLocalStorage from 'web/hooks/useLocalStorage';
import useClientSettings from 'web/hooks/useClientSettings';

import DayChooserModal from './components/day_chooser_modal';
import Header from './components/header';
import MobileMenu from './components/mobile_menu';
import {
  actions,
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  ENABLE_SCROLLING,
  DISABLE_SCROLLING,
} from './actions';
import {MarketLayoutStore, UpcomingPreorderPeriod} from './store_builder';

export const TOAST_TIMEOUT = 10000; // 10 seconds

export const reducer = <TFullState extends MarketLayoutStore>(
  state: TFullState,
  action: AnyAction,
): TFullState => {
  let {isScrollable, showAuthModalFlow, preAuthProductId} = state;
  const activeCard = state.activeCard || 'main';

  let newState = deckReducer(state, action);
  newState = modalReducer(newState, action);

  const {fulfillmentDaySummaries, currentFulfillmentDay} = fulfillmentDaysReducer(newState, action);
  const {zipCode, zipCodeError} = zipCodeReducer(newState, action);
  const recentlyLoggedInParam = 'recently-logged-in';
  const signInPage = {
    signUpUrl: '/signup',
    signOutUrl: '/logout',
    resetPasswordUrl: '/account/resetpassword',
    destinationUrl:
      state.signInPage?.destinationUrl != null
        ? state.signInPage.destinationUrl
        : `/home?${recentlyLoggedInParam}=true`,
  };

  const signUpPage = {
    signInUrl: '/signin',
    destinationUrl:
      state.signUpPage?.destinationUrl != null
        ? state.signUpPage.destinationUrl
        : `/home?${recentlyLoggedInParam}=true`,
  };

  switch (action.type) {
    case OPEN_AUTH_MODAL:
      isScrollable = false;
      showAuthModalFlow = true;
      preAuthProductId = action.productId;
      break;
    case CLOSE_AUTH_MODAL:
      isScrollable = true;
      showAuthModalFlow = false;
      break;
    case DISABLE_SCROLLING:
      isScrollable = false;
      break;
    case ENABLE_SCROLLING:
      isScrollable = true;
      break;
    case deckActions.ACTIVATE:
      // toggle scrollability for the menu card
      if (action.cardName === 'menu' && activeCard !== 'menu') {
        isScrollable = false;
      } else if (activeCard === 'menu') {
        // even action.cardName === 'menu' will go back to main
        isScrollable = true;
      }
      break;
    case deckActions.BACK:
      isScrollable = true;
      break;
    default:
    // noop
  }

  return {
    ...newState,
    basket: basketDuck.reducer(newState.basket, action),
    basketItemsByProductId: basketDuck.helpers.itemsById(newState.basket),
    currentFulfillmentDay,
    favorites: favoritesDuckReducer(newState.favorites, action),
    fulfillmentDaySummaries,
    isScrollable,
    preAuthProductId,
    showAuthModalFlow,
    zipCode,
    zipCodeError,
    signInPage,
    signUpPage,
  };
};

export interface ShoppingNotification {
  datePreamble?: string;
  dateString?: string;
  body?: string;
  cta?: string;
  categoryId?: string;
}

export interface ShoppingNotificationModalProps extends ShoppingNotification {
  onClose: () => void;
}

function ShoppingNotificationModal(props: ShoppingNotificationModalProps): React.ReactElement {
  return (
    <Modal className="refresh" onClose={props.onClose}>
      <div className="modal-content modal-content__shopping-notification">
        <div className="modal-header">
          <h2 className="thin">
            {props.datePreamble} <strong>{props.dateString}</strong>.
          </h2>
        </div>
        {props.body ? <p>{props.body}</p> : null}
      </div>
      <div className="modal-footer">
        <button type="button" className="button" onClick={props.onClose}>
          {props.cta}
        </button>
      </div>
    </Modal>
  );
}

export interface MarketLayoutProps {
  disableUpcomingOrdersBanner?: boolean;
  disableBasketDropdown?: boolean;
}

const MarketLayout: PageType<MarketLayoutStore | undefined, AnyAction, MarketLayoutProps> = (
  props,
) => {
  const [showSurveyPopIn, setShowSurveyPopIn] = useState<boolean>(false);
  const [isShoppingNotificationModalOpen, setIsShoppingNotificationModalOpen] = useState(true);
  const clientSettings = useClientSettings();
  const state = useSelector((s: MarketLayoutStore) => ({
    promoCodeRedeemed: s.promoCodeRedeemed,
    fulfillmentDaysByDay: s.fulfillmentDaysByDay,
    fulfillmentDaySummaries: s.fulfillmentDaySummaries,
    currentFulfillmentDay: s.currentFulfillmentDay,
    upcomingPreorderPeriods: s.upcomingPreorderPeriods,
    dayChooserModal: s.dayChooserModal ?? {},
    shoppingNotificationModal: s.shoppingNotificationModal,
    isScrollable: s.isScrollable,
    activeCard: s.activeCard,
    categories: s.categories,
    foodhub: s.foodhub,
    referrals: s.referrals,
    showAuthModalFlow: s.showAuthModalFlow,
    user: s.user,
    preAuthProductId: s.preAuthProductId,
    upcomingOrders: s.upcomingOrders,
    selectedCategoryId: s.selectedCategoryId,
    selectedSubcategoryId: s.selectedSubcategoryId,
    activePromotion: s.activePromotion,
    features: s.features,
  }));

  const [isDismissedSurveyPopin, setDismissedSurveyPopin] = useLocalStorage<boolean>(
    'dismissed-survey-popin',
    false,
  );
  const isSurveyPopinEnabled = clientSettings.survey.showSurveyPopIn;
  const hasAnsweredSurvey = state.user?.survey?.hasAnswered;

  useEffect(() => {
    setTimeout(() => {
      setShowSurveyPopIn(isSurveyPopinEnabled && !hasAnsweredSurvey && !isDismissedSurveyPopin);
    }, 2000);
  }, [isSurveyPopinEnabled, hasAnsweredSurvey, isDismissedSurveyPopin]);

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (state.isScrollable) {
      document.body.style.overflow = 'initial';
    } else {
      // TODO(aph): pretty sure this is busted on iPhone / iPad,
      //     can't be applied to body: http://stackoverflow.com/q/25519508/407845
      document.body.style.overflow = 'hidden';
    }
  }, [state.isScrollable]);

  const handleChangeDay = (day: string, shoppingNotification?: ShoppingNotification): void => {
    dispatch(
      (
        dispatchThunk: ThunkDispatch<MarketLayoutStore, null, AnyAction>,
        getState: () => MarketLayoutStore,
      ) => {
        const {currentFulfillmentDay} = getState();
        if (day !== currentFulfillmentDay) {
          dispatchThunk(basketDuck.actions.changeFulfillmentDay(day)).then(() => {
            if (shoppingNotification != null) {
              window.location.href = constructNotificationUrl(shoppingNotification).toString();
            } else {
              // reload without query params
              window.location.href = window.location.pathname;
            }
          });
        }
      },
    );
  };

  const closeAuthModal = (): void => {
    dispatch(actions.closeAuthModal());
  };
  const onAuthenticate = (): void => {
    dispatch(actions.authenticatedAuthModal());
  };
  const onMenuClicked = (): void => {
    dispatch(deckActions.activate('menu'));
  };
  const onSearchClicked = (): void => {
    dispatch(deckActions.back());
  };
  const closeDayChooserModal = (): void => {
    dispatch(modalActions.closeDayChooserModal());
  };
  const handleSelectPreorderPeriod = (preorderPeriod: UpcomingPreorderPeriod): void => {
    dispatch(modalActions.openDayChooserModal({preorderPeriod}));
  };

  const closeShoppingNotificationModal = (): void => {
    setIsShoppingNotificationModalOpen(false);
  };

  const dismissSurveyPopIn = (): void => {
    setShowSurveyPopIn(false);
    setDismissedSurveyPopin(true);
  };

  return (
    <div className="market-layout" data-testid="market-layout">
      <Header
        categories={state.categories}
        onMenuClicked={onMenuClicked}
        onSearchClicked={onSearchClicked}
        orders={props.disableUpcomingOrdersBanner ? [] : state.upcomingOrders}
        selectedCategoryId={state.selectedCategoryId}
        selectedSubcategoryId={state.selectedSubcategoryId}
        disableBasketDropdown={props.disableBasketDropdown}
        handleChangeDay={handleChangeDay}
        promoCodeRedeemed={state.promoCodeRedeemed}
        user={state.user}
      />
      {state.showAuthModalFlow && (
        <AuthFlowModal onCloseModal={closeAuthModal} onAuthenticate={onAuthenticate} />
      )}
      {showSurveyPopIn && <SurveyPopIn onDismiss={dismissSurveyPopIn} />}
      <Deck activeCard={state.activeCard}>
        <Card name="main">
          {props.children}

          {state.shoppingNotificationModal.show && isShoppingNotificationModalOpen ? (
            <ShoppingNotificationModal
              datePreamble={state.shoppingNotificationModal.datePreamble}
              dateString={state.shoppingNotificationModal.dateString}
              body={state.shoppingNotificationModal.body}
              cta={state.shoppingNotificationModal.cta}
              onClose={closeShoppingNotificationModal}
            />
          ) : null}

          {state.dayChooserModal?.open ? (
            <DayChooserModal
              fulfillmentDaysByDay={state.fulfillmentDaysByDay}
              fulfillmentDaySummaries={state.fulfillmentDaySummaries}
              currentFulfillmentDay={state.currentFulfillmentDay}
              features={state.features}
              upcomingPreorderPeriods={state.upcomingPreorderPeriods}
              onClose={closeDayChooserModal}
              handleChangeDay={handleChangeDay}
              handleSelectPreorderPeriod={handleSelectPreorderPeriod}
              preorderPeriod={state.dayChooserModal.preorderPeriod}
              productAvailabilityDays={state.dayChooserModal.productAvailabilityDays}
              tzid={window.settings.tzid}
              closeOnBack={state.dayChooserModal.closeOnBack}
              redirectOnSelect={state.dayChooserModal.redirectOnSelect}
            />
          ) : null}
        </Card>
        <Card name="menu">
          <MobileMenu />
        </Card>
      </Deck>
      <Footer />
      <ToastContainer autoClose={TOAST_TIMEOUT} pauseOnHover />
    </div>
  );
};

MarketLayout.pageName = 'Market Layout';
MarketLayout.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');
  return reducer(state, action);
};

function constructNotificationUrl(notification: ShoppingNotification): URL {
  const url = new URL(window.location.href);
  if (notification.categoryId) {
    url.pathname = notification.categoryId;
  }
  url.searchParams.set('showShoppingNotificationModal', '1');
  if (notification.datePreamble)
    url.searchParams.set('shoppingNotificationDatePreamble', notification.datePreamble);
  if (notification.dateString)
    url.searchParams.set('shoppingNotificationDate', notification.dateString);
  if (notification.body) url.searchParams.set('shoppingNotificationBody', notification.body);
  if (notification.cta) url.searchParams.set('shoppingNotificationCTA', notification.cta);

  return url;
}

export default MarketLayout;
